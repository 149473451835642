/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h3: "h3",
    pre: "pre",
    code: "code",
    h2: "h2"
  }, _provideComponents(), props.components), {Editor} = _components;
  if (!Editor) _missingMdxReference("Editor", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Themes allow you to easily make your editor look good with minimal effort. Quill features two officially supported themes: ", React.createElement(_components.a, {
    href: "#snow"
  }, "Snow"), " and ", React.createElement(_components.a, {
    href: "#bubble"
  }, "Bubble"), "."), "\n", React.createElement(_components.h3, null, "Usage"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html"
  }, "<!-- Add the theme's stylesheet -->\n<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/quill@2.0.0-beta.0/dist/quill.bubble.css\" />\n\n<script src=\"https://cdn.jsdelivr.net/npm/quill@2.0.0-beta.0/dist/quill.js\"></script>\n<script>\n  const quill = new Quill('#editor', {\n    theme: 'bubble', // Specify theme in configuration\n  });\n</script>\n")), "\n", React.createElement(_components.h2, null, "Bubble"), "\n", React.createElement(_components.p, null, "Bubble is a simple tooltip based theme."), "\n", React.createElement("div", {
    className: "standalone-container"
  }, React.createElement(Editor, {
    rootStyle: {
      height: '350px'
    },
    style: {
      border: '1px solid #ccc'
    },
    config: {
      placeholder: 'Compose an epic...',
      theme: 'bubble'
    }
  })), "\n", React.createElement("a", {
    className: "standalone-link",
    href: "/standalone/bubble/"
  }, React.createElement(_components.p, null, "Standalone")), "\n", React.createElement(_components.h2, null, "Snow"), "\n", React.createElement(_components.p, null, "Snow is a clean, flat toolbar theme."), "\n", React.createElement("div", {
    className: "standalone-container"
  }, React.createElement(Editor, {
    rootStyle: {
      height: '350px'
    },
    config: {
      placeholder: 'Compose an epic...',
      modules: {
        toolbar: [[{
          header: []
        }], ['bold', 'italic', 'underline', 'link'], [{
          color: []
        }, {
          background: []
        }], [{
          list: 'ordered'
        }, {
          list: 'bullet'
        }], ['clean']]
      },
      theme: 'snow'
    }
  })), "\n", React.createElement("a", {
    className: "standalone-link",
    href: "/standalone/snow/"
  }, React.createElement(_components.p, null, "Standalone")), "\n", React.createElement(_components.h3, null, "Customization"), "\n", React.createElement(_components.p, null, "Themes primarily control the visual look of Quill through its CSS stylesheet, and many changes can easily be made by overriding these rules. This is easiest to do, as with any other web application, by simply using your browser developer console to inspect the elements to view the rules affecting them."), "\n", React.createElement(_components.p, null, "Many other customizations can be done through the respective modules. For example, the toolbar is perhaps the most visible user interface, but much of the customization is done through the ", React.createElement(_components.a, {
    href: "/docs/modules/toolbar/"
  }, "Toolbar module"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
